
import { defineComponent } from "vue";
import Bus from "@/common/ts/Bus";
import "@/assets/tracking/build/tracking-min.js";
import "@/assets/tracking/build/data/face-min.js";

export default defineComponent({
  name: "validation",
  data() {
    return {
      video: null,
      screenshotCanvas: null,
      uploadLock: true, // 上传锁
      trackerTask: null,
    };
  },
  methods: {
    setFacePhoto(data) {
      Bus.$emit("setFacePhoto", data);
    },
    // getCompetence() {
    //     var _this = this;
    //     this.thisCancas = document.getElementById("canvas");
    //     this.thisContext = this.thisCancas.getContext("2d");
    //     this.thisVideo = document.getElementById("video");
    //     // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
    //     if (navigator.mediaDevices === undefined) {
    //         navigator.mediaDevices = {};
    //     }
    //     // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
    //     // 使用getUserMedia，因为它会覆盖现有的属性。
    //     // 这里，如果缺少getUserMedia属性，就添加它。
    //     if (navigator.mediaDevices.getUserMedia === undefined) {
    //         navigator.mediaDevices.getUserMedia = function (constraints) {
    //             // 首先获取现存的getUserMedia(如果存在)
    //             var getUserMedia =
    //                 navigator.webkitGetUserMedia ||
    //                 navigator.mozGetUserMedia ||
    //                 navigator.getUserMedia;
    //             // 有些浏览器不支持，会返回错误信息
    //             // 保持接口一致
    //             if (!getUserMedia) {
    //                 return Promise.reject(
    //                     new Error("getUserMedia is not implemented in this browser")
    //                 );
    //             }
    //             // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
    //             return new Promise(function (resolve, reject) {
    //                 getUserMedia.call(navigator, constraints, resolve, reject);
    //             });
    //         };
    //     }
    //     var constraints = {
    //         audio: false,
    //         video: {
    //             width: this.videoWidth,
    //             height: this.videoHeight,
    //             transform: "scaleX(-1)",
    //         },
    //     };
    //     navigator.mediaDevices
    //         .getUserMedia(constraints)
    //         .then(function (stream) {
    //             // 旧的浏览器可能没有srcObject
    //             if ("srcObject" in _this.thisVideo) {
    //                 _this.thisVideo.srcObject = stream;
    //             } else {
    //                 // 避免在新的浏览器中使用它，因为它正在被弃用。
    //                 _this.thisVideo.src = window.URL.createObjectURL(stream);
    //             }
    //             _this.thisVideo.onloadedmetadata = function (e) {
    //                 _this.thisVideo.play();
    //             };
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // },

    clearCanvas(){
      let canvas = document.getElementById("canvas");
      let context = (canvas as any).getContext("2d");
        context.clearRect(0, 0, (canvas as any).width, (canvas as any).height);
    },

    // 初始化设置
    initData() {
      (this.video as any) = document.getElementById("video") as HTMLElement;
      (this.screenshotCanvas as any) =
        document.getElementById("screenshotCanvas");
      let canvas = document.getElementById("canvas");
      let context = (canvas as any).getContext("2d");
      // 固定写法
      let tracker = new (window as any).tracking.ObjectTracker("face");
      tracker.setInitialScale(4);
      tracker.setStepSize(2);
      tracker.setEdgesDensity(0.1);
      //摄像头初始化
      this.trackerTask = (window as any).tracking.track("#video", tracker, {
        camera: true
      });
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      //let _this = this;
      tracker.on("track", function (event) {
        // 检测出人脸 绘画人脸位置
        context.clearRect(0, 0, (canvas as any).width, (canvas as any).height);
        event.data.forEach(function (rect) {
          context.font = "11px Helvetica";
          context.fillText("已识别到人脸，请点击检测", 100, 40);
          context.strokeStyle = "#0764B7";
          context.strokeRect(rect.x, rect.y, rect.width, rect.height);
          // 上传图片
          //_this.uploadLock && _this.screenshotAndUpload();
        });
      });
    },
    // 上传图片
    screenshotAndUpload() {
      // 上锁避免重复发送请求
      this.uploadLock = false;
      // 绘制当前帧图片转换为base64格式
      let canvas = this.screenshotCanvas;
      let video = this.video;
      let ctx = (canvas as any).getContext("2d");
      ctx.clearRect(0, 0, (canvas as any).width, (canvas as any).height);
      ctx.drawImage(video, 0, 0, (canvas as any).width, (canvas as any).height);
      let base64Img = (canvas as any).toDataURL("image/jpeg");
      // 使用 base64Img 请求接口即可
      console.log("base64Img:", base64Img);
      this.setFacePhoto(base64Img);
      // 请求接口成功以后打开锁
      // this.uploadLock = true;
      this.destroyed();
    },

    //关闭摄像头
    destroyed() {
      if (!this.video) {
        return;
      }
      (this.video as any).srcObject.getTracks()[0].stop();
      (this.trackerTask as any).stop();
      this.video = null;
      (this.$refs["btnClose"] as any).click();
    },
    doSomethingOnHidden() {
      // this.clearCanvas();
      this.destroyed();
    }
  },
  mounted() {
    window.addEventListener("hidden.bs.modal", this.doSomethingOnHidden); //函数名称保持一致
  },
  unmounted() {
    window.removeEventListener("hidden.bs.modal", this.doSomethingOnHidden); //函数名称保持一致
  }
});
